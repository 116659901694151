<template>
  <div>
    <div class="uk-grid-medium" uk-grid>
      <div class="uk-width-5-5">
        <div class="d-flex">
          <nav id="breadcrumbs" class="mb-3">
            <ul>
              <li>
                <a href="#"> <i class="uil-home-alt"></i> </a>
              </li>
              <li>{{ $t("exam.exams") }}</li>
              <li>{{ $t("exam.add") }}</li>
            </ul>
          </nav>
        </div>

        <template v-if="errorMessages">
          <div v-for="(error, index) in errorMessages" :key="index" class="uk-alert-danger" uk-alert>
            <a class="uk-alert-close" uk-close></a>
            <p style="color: red">
              <i class="uil-exclamation-triangle"></i> {{ error }}
            </p>
          </div>
        </template>
        <div v-if="successMessage" class="uk-alert-success" uk-alert>
          <a class="uk-alert-close" uk-close></a>
          <p style="color: green">
            <i class="uil-exclamation-triangle"></i> {{ successMessage }}
          </p>
        </div>
        <div class="card mt-4 position-relative">
          <div v-if="loading" class="loading-bar">
            <div class="text-info" uk-spinner="ratio: 4"></div>
          </div>
          <div class="card-body">
            <h4><i class="uil-question-circle"></i> {{ $t("exam.add") }}</h4>
            <hr />
            <div class="row">
              <div class="col-xl-9 m-auto">
                <form action="">
                  <div class="form-group row mb-3">
                    <label class="col-md-3 col-form-label" for="exam_title">{{
                      $t("exam.name")
                    }}<span class="required">*</span></label>
                    <div class="col-md-9">
                      <input id="exam_title" v-model="name" :placeholder="$t('exam.enter_name')" class="form-control"
                        required="" type="text" />
                    </div>
                  </div>

                  <div class="form-group row mb-3">
                    <label class="col-md-3 col-form-label" for="passmark">
                      {{ $t("exam.passmark") }} (0-100)<span class="required">*</span>
                    </label>
                    <div class="col-md-9">
                      <input id="passmark" v-model="passmark" :placeholder="$t('exam.enter_passmark')"
                        class="form-control" required="" type="text" />
                    </div>
                  </div>

                  <div class="form-group row mb-3">
                    <label class="col-md-3 col-form-label" for="duration">
                      {{ $t("exam.duration") }}<span class="required">*</span>
                    </label>
                    <div class="col-md-9">
                      <input id="duration" v-model="duration" :placeholder="$t('exam.enter_duration')"
                        class="form-control" required="" type="text" />
                    </div>
                  </div>

                  <div class="form-group row mb-3">
                    <label class="col-md-9 col-form-label" for="duration">
                      {{ $t("exam.is_mix_questions") }}
                    </label>
                    <div class="col-md-3">
                      <div class="d-flex justify-content-end  align-items-center">
                        <toggle-button v-model="is_mix_questions"
                          :labels="{ checked: $t('exam.yes'), unchecked: $t('exam.no') }" :sync="true" :width="120"
                          color="#ae71ff" />
                      </div>
                    </div>
                  </div>

                  <div class="form-group row mb-3">
                    <label class="col-md-9 col-form-label" for="duration">
                      {{ $t("exam.is_mix_answers") }}
                    </label>
                    <div class="col-md-3">
                      <div class="d-flex justify-content-end  align-items-center">
                        <toggle-button v-model="is_mix_answers"
                          :labels="{ checked: $t('exam.yes'), unchecked: $t('exam.no') }" :sync="true" :width="120"
                          color="#ae71ff" />
                      </div>
                    </div>
                  </div>
                  <div class="form-group row mb-3">
                    <label class="col-md-9 col-form-label" for="duration">
                      {{ $t("exam.show_right_answers") }}
                    </label>
                    <div class="col-md-3">
                      <div class="d-flex justify-content-end  align-items-center">
                        <toggle-button v-model="show_right_answers"
                          :labels="{ checked: $t('exam.yes'), unchecked: $t('exam.no') }" :sync="true" :width="120"
                          color="#ae71ff" />
                      </div>
                    </div>
                  </div>

                  <div class="form-group row mb-3">
                    <label class="col-md-9 col-form-label" for="duration">
                      {{ $t("exam.show_user_answers") }}
                    </label>
                    <div class="col-md-3">
                      <div class="d-flex justify-content-end  align-items-center">
                        <toggle-button v-model="show_user_answers"
                          :labels="{ checked: $t('exam.yes'), unchecked: $t('exam.no') }" :sync="true" :width="120"
                          color="#ae71ff" />
                      </div>
                    </div>
                  </div>

                  <div class="form-group row mb-3">
                    <label class="col-md-9 col-form-label" for="duration">
                      {{ $t("exam.show_result") }}
                    </label>
                    <div class="col-md-3">
                      <div class="d-flex justify-content-end  align-items-center">
                        <toggle-button v-model="show_result"
                          :labels="{ checked: $t('exam.yes'), unchecked: $t('exam.no') }" :sync="true" :width="120"
                          color="#ae71ff" />
                      </div>
                    </div>
                  </div>

                  <div class="form-group row mb-3">
                    <label class="col-md-9 col-form-label" for="duration">
                      {{ $t("exam.is_compulsory") }}
                    </label>
                    <div class="col-md-3">
                      <div class="d-flex justify-content-end  align-items-center">
                        <toggle-button v-model="is_compulsory"
                          :labels="{ checked: $t('exam.yes'), unchecked: $t('exam.no') }" :sync="true" :width="120"
                          color="#ae71ff" />
                      </div>
                    </div>
                  </div>

                  <ul id="userAddOptions" class="uk-tab page-tab" uk-switcher="animation: uk-animation-fade">
                    <li>
                      <a href="#" id="add_from_manuel">
                        <h4>
                          Soru ekle
                        </h4>
                      </a>
                    </li>
                    <li>
                      <a href="#" id="add_from_excel">
                        <h4><i class="uil-table"></i> Excel ile Ekle</h4>
                      </a>
                    </li>
                  </ul>
                  <ul class="uk-switcher">
                    <li>
                      <questions ref="myEvent" :importedExam="importedExam" :questions="[]" action="create"
                        modelType="Exam">
                      </questions>

                    </li>
                    <li>
                      <div class="uk-card-body p-0 uk-grid-collapse uk-grid-large" uk-grid>
                        <div class="uk-padding-large position-relative uk-width-1-3@m">
                          <h5><i class="uil-table"></i> Excel dosyası yükle:</h5>
                          <div class="form-group row mb-3">
                            <div class="col-md-12">
                              <form action="api/something" enctype="multipart/form-data" method="post">
                                <input id="fileToUpload" :disabled="is_loading" accept=".xls, .xlsx" name="fileToUpload"
                                  type="file" @change="setFile" @click="resetFileInfo" />
                                <!-- <input class="btn btn-outline-primary btn-sm btn-block" name="uploadfile" type="button"
                                  value="Listele" @click="excelToJSON" /> -->
                              </form>

                              <a class="mb-4" href="#" style="font-size: 14px; color: #ae71ff"
                                @click="exportSampleFile"><i class="uil-download-alt"></i> Şablon Excel
                                Dokümanını İndir</a>
                              <i class="uil-info-circle" style="font-size: 1rem"
                                uk-tooltip="title: Kullanıcıları sisteme eklemek amacıyla şablon haline getirilmiş excel dokümanını indirin; pos: bottom-center"></i>
                            </div>
                          </div>
                          <hr class="mt-5" />
                          <!-- <div class="form-group row mb-3">
                            <div class="col-md-12">
                              <label class="col-form-label" for="company">{{ $t('general.choose_group') }}</label>
                              <select v-model="user_group" class="selectpicker2">
                                <option v-for="item in formattedUserGroup" :value="item.id">
                                  {{ item.name }}
                                </option>
                              </select>
                            </div>
                          </div> -->
                        </div>

                        <div class="uk-padding-large uk-width-expand d-flex flex-column justify-content-between px-2"
                          style="border-left: 1px solid #ccc">
                          <default-loading v-if="is_loading"></default-loading>
                          <template>

                            <div class="uk-position-relative my-4 text-center"
                              style="overflow-y: scroll; padding-right: 2rem">
                              <!-- <h4 v-if="is_added" class="text-left">
                                "{{ fileInfo.name }}" dosyasında
                                {{ fileInfo.count }} kişi listeleniyor
                              </h4> -->
                              <div class="table-responsive">
                                <table class="table align-items-center">
                                  <thead>
                                    <tr>
                                      <th scope="col">Soru*
                                      </th>
                                      <th scope="col">A Şıkkı
                                      </th>
                                      <th scope="col">B Şıkkı
                                      </th>
                                      <th scope="col">C Şıkkı
                                      </th>
                                      <th scope="col">D Şıkkı
                                      </th>
                                      <th scope="col">E Şıkkı
                                      </th>
                                      <th scope="col">Doğru Cevap
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody class="list">
                                    <template v-for="(item, index) in importedExam">
                                      <tr v-if="item" :key="index">
                                        <!-- <td class="text-right"> -->
                                        <!-- <div class="actions mr-2">
                                            <a class="btn btn-icon btn-hover btn-lg btn-circle" href="#"
                                              uk-tooltip="Listeden Kaldır" @click="removeIndex(index)">
                                              <i class="uil-times-circle text-danger"></i>
                                            </a>
                                          </div> -->
                                        <!-- </td> -->
                                        <td>{{ item[0] }}</td>
                                        <td>{{ item[1] }}</td>
                                        <td>{{ item[2] }}</td>
                                        <td>{{ item[3] }}</td>
                                        <td>{{ item[4] }}</td>
                                        <td>{{ item[5] }}</td>
                                        <td>{{ item[6] }}</td>
                                        <td>{{ item[7] }}</td>
                                        <td>{{ item[8] }}</td>
                                      </tr>
                                    </template>
                                  </tbody>
                                </table>
                              </div>
                            </div>

                          </template>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <div class="d-flex justify-content-end  align-items-center">
                    <toggle-button v-model="is_default"
                      :labels="{ checked: $t('exam.default'), unchecked: $t('exam.not_default') }" :sync="true"
                      :width="120" color="#ae71ff" />
                  </div>

                  <div class="uk-card-footer float-right pb-0 pr-0">
                    <button class="uk-button uk-button-primary small" type="button" @click="saveForm">
                      <i class="icon-feather-arrow-right"></i>{{ $t("general.save") }}
                    </button>
                    <button class="uk-button uk-button-primary small" style="margin-left:5vh" type="button"
                      @click="saveForm('assign_page')">
                      <i class="uil-location-arrow"></i> {{ $t("Kaydet ve Atama yap") }}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import store from "@/core/services";
// import questions_module, {
//   BASE_URL as QUESTIONS_BASE_URL,
//   MODULE_NAME as QUESTIONS_MODULE_NAME,
//   GET_ITEMS as QUESTIONS_GET_ITEMS,
//   ITEMS as QUESTIONS_ITEMS,
// } from "@/core/services/store/question.module";
import Questions from "@/view/components/question/Questions";

import module, {
  BASE_URL, CREATE_ITEM, ERROR,
  LOADING, MODULE_NAME,
  SUCCESS,
  MODULE_NAME as EXAM_MODULE,
  IMPORT_BASE_URL as EXAM_IMPORT_URL,
} from "@/core/services/store/exam.module";
import axios from "axios";
import moment from "moment";
import readXlsxFile from "read-excel-file";
import DefaultLoading from "@/view/components/loading/DefaultLoading";
//const _QUESTIONS_MODULE_NAME = QUESTIONS_MODULE_NAME;
const _MODULE_NAME = MODULE_NAME;

export default {
  name: "examAdd",
  components: {
    draggable, Questions,
    DefaultLoading
  },
  data() {
    return {
      errorMessages: [],
      successMessage: null,
      //selectedQuestions: [],
      name: null,
      passmark: 50, //max: 100
      is_mix_questions: true,
      is_mix_answers: true,
      show_right_answers: true,
      show_user_answers: true,
      show_result: true,
      is_compulsory: true,
      duration: 20,
      is_default: false,
      questions: [],
      is_loading: false,
      file: null,
      file_value: null,
      importedExam: null,
      fileInfo: {
        name: "",
        count: 0,
        file: null,
      },
      is_added: false,

    };
  },
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule);
      }
    }
    // registerStoreModule(_QUESTIONS_MODULE_NAME, questions_module);
    registerStoreModule(_MODULE_NAME, module);
  },
  computed: {
    loading: {
      get() {
        return store.getters[MODULE_NAME + "/" + LOADING];
      },
      set(value) {
      },
    },
    // questions: {
    //   get() {
    //     return store.getters[QUESTIONS_MODULE_NAME + "/" + QUESTIONS_ITEMS];
    //   },
    //   set(value) {
    //   },
    // },
    error: {
      get() {
        return store.getters[_MODULE_NAME + "/" + ERROR];
      },
      set(value) {
      },
    },
    success: {
      get() {
        return store.getters[_MODULE_NAME + "/" + SUCCESS];
      },
      set(value) {
      },
    },
    // formatQuestionsItem() {
    //   let results = [];
    //   if (this.questions != null && this.questions.length ) {
    //     this.questions.forEach(currentItem => {
    //       results.push({
    //         id: currentItem.id,
    //         text: currentItem.text,
    //         order: currentItem.id,
    //       })
    //     });
    //     return results;
    //   }
    // }
  },
  methods: {
    removeIndex(key) {
      let obj = this.importedExam;
      this.importedExam = delete obj[key];
      this.importedExam = obj;
      this.removedItems.push(key);
    },
    resetFileInfo() {
      this.is_added = false;
      this.fileInfo.file = null;
      // this.importedExam = null;
      this.removedItems = [];
      $("#fileToUpload").val("");
    },
    setFile(e) {
      this.file_value = e.target.value;
      this.file = e.target.files[0];
      this.excelToJSON();
    },
    exportSampleFile() {
      let self = this;
      let config = {
        responseType: "blob",
        headers: this.apiHeaders,
      };
      axios
        .post(
          process.env.VUE_APP_BACKEND_APP_URL + "/api/exam/exam-download-sample-excel",
          [],
          config
        )
        .then((response) => {
          let fileURL = window.URL.createObjectURL(new Blob([response.data]));
          let fileLink = document.createElement("a");

          fileLink.href = fileURL;
          let day = moment().format("YYYYMMDDHHMMSS");
          let fileName = "exam_sample_" + day + ".xlsx";
          fileLink.setAttribute("download", fileName);
          document.body.appendChild(fileLink);
          fileLink.click();

          self.showExistRecords = true;
          self.filterResultCode(self.active_item_id);
          self.filterResult();
        })
        .catch((error) => {
        });
    },
    updateImporteValues(values, count, fileName) {
      count--;
      this.importedExam = values;
      this.fileInfo.name = fileName;
      this.fileInfo.count = count;
      this.is_added = count > 0 ? true : false;
      this.is_loading = false;
    },
    excelToJSON(e) {
      if (this.file) {
        this.is_loading = true;
        let fileName = this.file_value
          ? this.file_value.split(/(\\|\/)/g).pop()
          : "";
        let count;
        this.fileInfo.file = this.file;
        readXlsxFile(this.file).then((rows) => {
          delete rows[0];
          count = rows.length;
          this.updateImporteValues(rows, count, fileName);
        });
      }
    },
    saveForm(process_type) {
      let self = this;
      let validated = true;
      console.log(this.importedExam);
      let formData = new FormData();
      let myQuestions = self.$refs.myEvent.handleNewQuestions();
      this.importedExam
      // let myQuestions = 

      if (self.name == null || self.name.length < 1) {
        self.errorMessages.push(self.$t("validation.required_field").replace(":NAME", self.$t("exam.name")));
        self.scrollToTop();
        validated = false;
      }

      if (myQuestions.length === 0) {
        self.errorMessages.push(this.$t("validation.required_field").replace(":NAME", self.$t("exam.question")));
        self.scrollToTop();
        validated = false;
      }

      if (self.duration == null || +self.duration < 1) {
        self.errorMessages.push(this.$t("validation.required_field").replace(":NAME", self.$t("exam.duration")));
        self.scrollToTop();
        validated = false;
      }

      //formData.append('questions', JSON.stringify(myQuestions));


      myQuestions.forEach((question, questionIndex) => {
        let isCorrectCount = 0;
        let hasNullName = false;
        let hasNullMedia = false;

        if (question.text === '') {
          self.errorMessages.push(self.$t("validation.required_field").replace(":NAME", self.$t("question.attributes.text")));
          self.scrollToTop();
          return false;
        }

        question.options.forEach(option => {
          if (option.name === '') {
            hasNullName = true;
          }

          if (option.is_correct) {
            isCorrectCount++
          }

          if (typeof option.media === "undefined") {
            hasNullMedia = true;
          }
        });

        if (hasNullName && (question.type !== 4 && question.type !== 5)) {
          self.errorMessages.push(self.$t("validation.required_field").replace(":NAME", self.$t("question.attributes.option_name")));
          self.scrollToTop();
          return false;
        }

        if (isCorrectCount === 0) {
          self.errorMessages.push(self.$t("question.required.true_count"));
          self.scrollToTop();
          return false;
        }

        if (+question.type === 1 && isCorrectCount > 1) {
          self.errorMessages.push(self.$t('question.required.true_count'));
          self.scrollToTop()
          return false;
        }

        if (hasNullMedia && (question.type === 4 || question.type === 5)) {
          self.errorMessages.push(self.$t("validation.required_field").replace(":NAME", self.$t("question.attributes.option_media")));
          self.scrollToTop();
          return false;
        }
        formData.append('questions[' + questionIndex + '][type]', question.type);
        formData.append('questions[' + questionIndex + '][text]', question.text);
        formData.append('questions[' + questionIndex + '][media]', question.media);
        question.options.forEach((option, optionIndex) => {
          formData.append('questions[' + questionIndex + '][options][' + optionIndex + '][name]', option.name);
          formData.append('questions[' + questionIndex + '][options][' + optionIndex + '][is_correct]', option.is_correct);
          if (question.type === 4 || question.type === 5) {
            formData.append('questions[' + questionIndex + '][options][' + optionIndex + '][media]', option.media);
          }
        });
      });
      formData.append("name", self.name);
      formData.append("passmark", self.passmark);
      formData.append("duration", self.duration);
      formData.append("is_default", self.is_default ? 1 : 0);
      formData.append("is_mix_questions", self.is_mix_questions ? 1 : 0);
      formData.append("is_mix_answers", self.is_mix_answers ? 1 : 0);
      formData.append("show_right_answers", self.show_right_answers ? 1 : 0);
      formData.append("show_user_answers", self.show_user_answers ? 1 : 0);
      formData.append("show_result", self.show_result ? 1 : 0);
      formData.append("is_compulsory", self.is_compulsory ? 1 : 0);
      if (validated) {
        store
          .dispatch(MODULE_NAME + "/" + CREATE_ITEM, {
            url: BASE_URL,
            contents: formData,
          }).then((response) => {
            if (process_type === 'assign_page') {
              this.$router.push({
                name: "company.assignments.add",
                params: { data: { id: response.content_id, title: response.name, name: 'Sınav' } }
              });
            } else {
              this.successMessage = this.$t("general.successfully_created");
              this.$router.push({ name: "company.exams.list" });
            }
          });
      }
    },
    submitFile() {
      let self = this;
      self.is_loading = true;
      self.formErrors = [];
      let formData = new FormData();
      formData.append("file", self.fileInfo.file);
      formData.append("removed", self.removedItems);
      formData.append("user_group", self.user_group);
      formData.append("company_id", self.company_id);
      // if (
      //   this.isSuperAdmin &&
      //   (self.company_id === null || self.company_id === "")
      // ) {
      //   self.is_error = true;
      //   self.formErrors.push(self.$t("validation.one_item_required"));

      //   return false;
      // }
      // if (!self.fileInfo.file) {
      //   self.is_error = true;
      //   self.formErrors.push(self.$t("validation.file_required"));
      //   return false;
      // }
      // if (self.fileInfo.count === self.removedItems.length) {
      //   self.is_error = true;
      //   self.formErrors.push(self.$t("validation.one_item_required"));

      //   return false;
      // }
      this.scrollToTop();
      store
        .dispatch(EXAM_MODULE + "/" + IMPORT_EXAM, {
          url: EXAM_IMPORT_URL,
          contents: formData,
          returnType: "stateless",
          acceptPromise: true,
        })
        .then(() => {
          self.is_loading = false;
          if (!self.errors) {
            self.resetFileInfo();
            self.$router.push({
              name: "company.user.list",
              params: {
                succesMessage: this.$t("general.successfully_imported"),
              },
            });
          }
        });
    },
    resetMessages() {
      this.successMessage = null;
      this.errorMessages = [];
    },
    scrollToTop() {
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
    },
  },
  mounted() {
  },
  watch: {
    errors: function (val) {
      if (val) {
        this.scrollToTop();
        this.errorMessages.push(val);
      }
    },
    successMessage() {
      this.debouncedResetValues();
    },
    errorMessages() {
      this.debouncedResetValues();
    },
  },
  created: function () {
    this.debouncedResetValues = _.debounce(this.resetMessages, 5000);
  },
};
</script>
<style scoped>
.selected_questions {
  color: #ae71ff;
  border: 1px solid #eaeaea;
  display: block;
  font-size: 13px;
  line-height: 33px;
  padding: 1px 7px;
  border-radius: 8px;
}

.loading-bar {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 999;
  background: rgba(238, 238, 238, 0.5);
  display: flex;
  justify-content: center;
  align-items: center
}
</style>
